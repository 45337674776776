<template>
  <div class="p-6 md:p-10">
    <div class="mb-12 flex items-center justify-between md:mb-2">
      <nuxt-link to="/">
        <img
          src="/images/flik-logo.svg"
          alt="Flik Logo"
          class="h-7 object-contain"
          height="30"
        />
      </nuxt-link>
      <NuxtLink to="/" class="text-sm text-gray-400">
        Back to dashboard
      </NuxtLink>
    </div>

    <div class="mb-10 flex flex-col items-center">
      <h1 class="mb-2 text-center text-2xl font-bold text-gray-900">
        Complete Your Onboarding
      </h1>
      <div class="w-[360px] text-center">
        <p class="text-sm text-gray-600">
          To verify your account, we kindly request a few key details to
          optimize your account experience.
        </p>
      </div>
    </div>

    <div class="flex justify-center">
      <slot />
    </div>
  </div>
</template>
